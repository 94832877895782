<template>
    <div>
        <el-dialog title="マッチング申請" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item label="申請" :label-width="formLabelWidth">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="内容を入力下さい"
                        v-model="form.name">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="addFriend">確 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import service from "@/service";

    export default {
        data() {
            return {
                dialogFormVisible: false,
                userData: {},
                form: {
                    name: '',
                },
                formLabelWidth: '120px'
            }
        },
        methods: {
            dialogForm(data) {
                this.userData = data
            },
            // 好友申请
            addFriend() {
                service.personal.addFriend({ fid: this.userData.userid,msg: this.form.name}).then((res) => {
                    if (res.status === 200) {
                        this.$message.success(res.info);
                        this.$parent.getPersonalPushPair(1)
                    }else if(res.status === 1) {
                        this.$message.success(res.info);
                    }
                    this.dialogFormVisible = false
                    this.form.name = ''
                })
                .catch((err) => {
                    console.log(err);
                })
            },
            // 取消
            cancel() {
                this.dialogFormVisible = false
                this.form.name = ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-form-item__label {
        float: none;
        margin-left: 30px;
    }
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
        margin: 0 30px !important;
    }
</style>