<template>
  <div>
    <div class="main">
      <div class="message">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="未読メッセージ" name="1">
            <div class="wrap">
              <div class="message-box" v-for="(item, index) in messageList" :key="index">
                  <div>{{ item.nickname }}： {{ item.message }}</div>
                <p>
                  <span>{{ item.sendtime }}</span>
                </p>
                <b @click="deleteMessage(item)">x</b>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="my-project">
        <div class="public">
          <span>友達申請</span>
          <span @click="goMyProject"></span>
        </div>
        <el-table :data="addFirendList" :header-cell-style="headClass" border style="width: 100%">
            <el-table-column prop="nickname" label="ニックネーム" align="center" width="220"></el-table-column>
            <el-table-column prop="id" label="プロフィール" align="center" width="220">
              <template scope="scope">
                <span @click="handleClick_(scope.row)" style="cursor:pointer;color:#1875d2">プロフィール</span>
              </template>
            </el-table-column>
              <el-table-column prop="id" label="送信" align="center">
                <template scope="scope">
                  <span style="cursor:pointer;color:#1875d2" @click="sendMessage(scope.row)">送信</span>
                </template>
            </el-table-column>
             <el-table-column prop="id" label="操作" align="center">
                <template scope="scope">
                  <div v-if="scope.row.isadd == 3">
                      <el-button type="text" icon="el-icon-check" size="small"  @click="agree(scope.row, 1)">受入</el-button>
                      <el-button type="text" icon="el-icon-close" size="small" @click="agree(scope.row, 2)">拒否</el-button>
                  </div>
                  <div v-if="scope.row.isadd == 1">
                    <span style="color:#666; padding: 0 10px">受入</span>
                  </div>
                  <div v-if="scope.row.isadd == 2">
                    <span style="color:#666; padding: 0 10px">拒否</span>
                  </div>
                  <!-- <div v-if="scope.row.isadd == 3">
                    <span style="color:#666; padding: 0 10px">申请中</span>
                  </div> -->
                </template>
            </el-table-column>
        </el-table>
      </div>
      <div class="my-project" v-if="getRole">
        <!-- 我的项目 -->
        <div class="public">
          <span>私のプロジェクト</span>
          <!-- <span @click="goMyProject">もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="tableData" :header-cell-style="headClass" border style="width: 100%" >
            <el-table-column prop="title" label="漫画タイトル" align="center" width="220">
              <template scope="scope">
                <span class="style" @click="toDetails(scope.row,true)">
                  {{ scope.row.title }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="原作者" align="center" width="180">
            </el-table-column>
            <el-table-column prop="progress" align="center" label="ステータス">
              <template scope="scope">
                <font> {{ scope.row.progress || "進行中" }} </font>
              </template>
            </el-table-column>
            <el-table-column prop="updated_at" align="center" label="最終更新時間">
              <template scope="scope">
                <font> {{ scope.row.update_at || "-" }} </font>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="end_at" align="center" label="校了締め日">
              <template scope="scope">
                <font> {{ scope.row.end_at || "-" }} </font>
              </template>
            </el-table-column> -->

            <el-table-column prop="id" label="登録情報修正" align="center">
              <template scope="scope">
                <el-button @click="modifyProjectInfo(scope.row)" type="text" icon="el-icon-s-tools" size="small">変更
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="page-box" style="margin-top:10px;text-align:right" v-if="projectCount>0">
            <el-pagination
              @current-change="projectHandleCurrentChange"
              :current-page="currentPage"
              :page-size="5"
              layout="total,  prev, pager, next, jumper"
              :total="projectCount"
            >
            </el-pagination>
        </div> -->
        </div>
      </div>
       <div class="new-project-btn" v-if="false">
        <!-- 新项目立项 -->
        <span @click="goNewProject">+ プロジェクト作成</span>
      </div>
      <div class="my-project" v-if="isShowPartList">
        <div class="public">
          <!-- 我参与的项目 -->
          <span>参加したプロジェクト</span>
          <!-- <span @click="goMyProject">もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="part" :header-cell-style="headClass" border style="width: 100%">
            <el-table-column prop="title" label="漫画タイトル" align="center" width="220">
              <template scope="scope">
                <span class="style" @click="toDetails(scope.row,false)">
                  {{ scope.row.title }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="原作者" align="center" width="180">
            </el-table-column>
            <el-table-column prop="progress" align="center" label="ステータス">
              <template scope="scope">
                <font> {{ scope.row.progress || "進行中" }} </font>
              </template>
            </el-table-column>
            <el-table-column prop="updated_at" align="center" label="最終更新時間">
              <template scope="scope">
                <font> {{ scope.row.update_at || "-" }} </font>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="end_at" align="center" label="校了締め日">
              <template scope="scope">
                <font> {{ scope.row.check_at || "-" }} </font>
              </template>
            </el-table-column> -->
          </el-table>
            <!-- <div class="page-box" style="margin-top:10px;text-align:right">
            <el-pagination
              @current-change="cjxmHandleCurrentChange"
              :current-page="currentPage"
              :page-size="10"
              layout="total,  prev, pager, next, jumper"
              :total="cjxmCount"
            >
            </el-pagination>
        </div> -->
        </div>
      </div>
     
      <div class="my-project" v-if="isShowCase">
        <div class="public">
          <!-- 我参与的案件 -->
          <span>参加した案件</span>
          <!-- 查看更多 -->
          <!-- <span @click="goMyProject('caseList')">もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="caseList" :header-cell-style="headClass" border style="width: 100%">
            <!-- 匹配度 -->
            <el-table-column prop="title" label="漫画タイトル" align="center" width="220">
              <template scope="scope">
                <span class="style" @click="toDetails(scope.row,false)">
                  {{ scope.row.title }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="作家名" align="center" width="180">
            </el-table-column>
            <el-table-column prop="name" align="center" label="案件名">
            </el-table-column>
            <!-- 最终更新 -->
            <el-table-column prop="updated_at" align="center" label="最終更新時間">
              <template scope="scope">
                <font> {{ scope.row.update_at || "-" }} </font>
              </template>
            </el-table-column>
            <!-- 校对截止日 -->
            <!-- <el-table-column prop="end_at" align="center" label="校了締め日">
              <template scope="scope">
                <font> {{ scope.row.check_at || "-" }} </font>
              </template>
            </el-table-column> -->
          </el-table>
          <!-- <div class="page-box" style="margin-top:10px;text-align:right">
            <el-pagination
              @current-change="cjajHandleCurrentChange"
              :current-page="currentPage"
              :page-size="5"
              layout="total,  prev, pager, next, jumper"
              :total="cjajCount"
            >
            </el-pagination>
        </div> -->
        </div>
      </div>
      <div class="pair-user" v-if="isShowFirend">
        <div class="public">
          <span v-if="this.loginAttr === 2">お勧め原作者</span>
          <span v-if="this.loginAttr === 1">お勧め編集者</span>
          <!-- <span>もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="pairUser" :header-cell-style="headClass" border style="width: 100%">
            <el-table-column prop="match" label="マッチング度" align="center" width="180">
              <template scope="scope">
                <p>{{Math.ceil(scope.row.match * 100) }}%</p>
              </template>
            </el-table-column>

            <el-table-column prop="loginattr" label="ユーザ属性" align="center">
              <template scope="scope">
                <p>{{ scope.row.loginattr == 1 ? "原作者" : "編集者" }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="ニックネーム" align="center">
            </el-table-column>
            <el-table-column prop="userid" label="マッチング申請" align="center">
              <template scope="scope">
                <span v-if="scope.row.isadd == 1" class="style" @click="addDialog(scope.row)">
                  {{ scope.row.userid ? "申請" : "" }}</span>
                  <span v-if="scope.row.isadd == 2">受入済</span>
                  <span v-if="scope.row.isadd == 3">拒否済</span>
                  <span v-if="scope.row.isadd == 4">申請済</span>
              </template>
            </el-table-column>
            <el-table-column prop="userid" label="プロフィール" align="center">
              <template scope="scope">
                <span class="style" @click="handleClick_(scope.row)">
                  {{ scope.row.userid ? "プロフィール" : "" }}</span>
              </template>
            </el-table-column>
          </el-table>
           <!-- <div class="page-box" style="margin-top:10px;text-align:right">
            <el-pagination
              @current-change="tjHandleCurrentChange"
              :current-page="currentPage"
              :page-size="5"
              layout="total,  prev, pager, next, jumper"
              :total="tjCount"
            >
            </el-pagination>
        </div> -->
        </div>
      </div>
      <div class="pair-user" v-if="isShowFirend">
        <div class="public">
          <span>マッチング申請リスト</span>
          <!-- <span>もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="resuestList" :header-cell-style="headClass" border style="width: 100%">
            <el-table-column prop="nickname" label="ニックネーム" align="center">
            </el-table-column>
            <el-table-column prop="type" label="マッチング申請" align="center">
              <template scope="scope">
                <el-button type="text" icon="el-icon-check" size="small" @click="agree(scope.row, 1)">
                  {{ scope.row.type ? "受入" : "" }}</el-button>
                  <el-button type="text" icon="el-icon-check" size="small" @click="agree(scope.row, 2)">
                  {{ scope.row.type ? "拒否" : "" }}</el-button>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="type" label="拒否" align="center">
              <template scope="scope">
                <span class="style" @click="agree(scope.row, 2)">
                  {{ scope.row.type ? "拒否" : "" }}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="id" label="詳細" align="center">
              <template scope="scope">
                <span @click="handleClick_(scope.row)"  class="style" > {{ scope.row.id ? "プロフィール" : "" }}</span>
              </template>
            </el-table-column>
          </el-table>
          
        </div>
      </div>
      <div class="pair-user" v-if="isAdvanced">
        <div class="public">
          <span>お勧めプロジェクト</span>
          <!-- <span>もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="qhRecruit" :header-cell-style="headClass" border style="width: 100%">
            <!-- 匹配度 -->
            <el-table-column prop="percent" label="マッチング度" align="center" width="180">
              <template scope="scope">
                <p>{{ Math.ceil(scope.row.percent * 100)}}%</p>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="漫画名" align="center">
            </el-table-column>
            <!-- 进度 -->
            <el-table-column prop="position" label="属性" align="center">
            </el-table-column>
            <!-- <el-table-column prop="progress" label="ステータス" align="center">
            </el-table-column> -->
            <!-- 应聘 -->
            <el-table-column prop="speed" label="応募" align="center">
              <template scope="scope">
                <!-- <span @click="apply(scope.row)" class="style">応募</span> -->
                <span v-if="!scope.row.progress" @click="apply(scope.row)" class="style">応募</span>
                <span v-else> {{ scope.row.progress }} </span>
              </template>
            </el-table-column>
            <el-table-column label="詳細" align="center">
              <template scope="scope">
                <span @click="goSynopsis(scope.row,false)" class="style">詳細</span>
              </template>
            </el-table-column>
          </el-table>
            <!-- <div class="page-box" style="margin-top:10px;text-align:right" v-if="qhzpCount > 0">
            <el-pagination
              @current-change="qhZpHandleCurrentChange"
              :current-page="currentPage"
              :page-size="5"
              layout="total,  prev, pager, next, jumper"
              :total="qhzpCount"
            >
            </el-pagination>
        </div> -->
        </div>
      </div>
      <div class="pair-user" v-if="isShowCase">
        <div class="public">
          <span>お勧め案件</span>
          <!-- <span>もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="ajRecruit" :header-cell-style="headClass" border style="width: 100%">
            <!-- 匹配度 -->
            <el-table-column prop="percent" label="マッチング度" align="center" width="180">
              <template scope="scope">
                <p>{{  Math.ceil(scope.row.percent * 100)  }}%</p>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="漫画名" align="center">
            </el-table-column>
            <el-table-column prop="name" label="案件名" align="center">
            </el-table-column>
            <!-- 进度 -->
            <el-table-column prop="position" label="属性" align="center">
            </el-table-column>
             <!-- <el-table-column prop="progress" label="ステータス" align="center">
            </el-table-column> -->
            <!-- 应聘 -->
            <el-table-column prop="speed" label="応募" align="center">
              <template scope="scope">
                <span v-if="!scope.row.progress" @click="apply(scope.row)" class="style">応募</span>
                <span v-else> {{ scope.row.progress }} </span>
              </template>
            </el-table-column>
            <el-table-column label="詳細" align="center">
              <template scope="scope">
                <span @click="goSynopsis(scope.row,true)" class="style">詳細</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="page-box" style="margin-top:10px;text-align:right">
            <el-pagination
              @current-change="qhzpHandleCurrentChange"
              :current-page="currentPage"
              :page-size="5"
              layout="total,  prev, pager, next, jumper"
              :total="ajzpCount"
            >
            </el-pagination>
        </div> -->
        </div>
      </div>
      <div class="pair-user" v-if="getRole">
        <div class="public">
          <span>プロジェクト応募情報</span>
          <!-- <span>もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="qhApply" :header-cell-style="headClass" border style="width: 100%">
            <el-table-column prop="nickname" label="応募者" align="center" width="180">
            </el-table-column>
            <el-table-column prop="projectname" label="漫画名" align="center" width="180">
            </el-table-column>
            <el-table-column prop="positionname" label="応募職業" align="center" width="">
            </el-table-column>
            <el-table-column prop="introduce " label="ポートフォリオ" align="center" width="180">
              <template scope="scope">
                <p v-for="(item, index) in scope.row.introduce" :key="index">
                  {{ item }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="プロフィール" align="center" width="180">
              <template scope="scope">
               <span @click="handleClick_(scope.row)" style="color: #1875d2; cursor: pointer;">プロフィール</span>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="操作" align="center" width="">
              <template scope="scope">
                <template v-if="scope.row.isemploy == 0">
                  <el-button @click="agreeJoin(scope.row)" type="text" icon="el-icon-check" size="small">受入</el-button>
                  <el-button @click="refuseJoin(scope.row)" type="text" icon="el-icon-close" size="small">拒否</el-button>
                </template>
                <template v-else-if="scope.row.isemploy == 1">
                  <span>受入</span>
                </template>
                <template v-else-if="scope.row.isemploy == 2">
                  <span>拒否</span>
                </template>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="id" label="メッセージ" align="center" width="">
              <template scope="scope">
                <el-button @click="sendMessage(scope.row)" type="text" icon="el-icon-message" size="small">メッセージ
                </el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <!-- <div class="page-box" style="margin-top:10px;text-align:right">
            <el-pagination
              @current-change="qhHandleCurrentChange"
              :current-page="qihuacurrentPage"
              :page-size="5"
              layout="total,  prev, pager, next, jumper"
              :total="ajCount"
            >
            </el-pagination>
        </div> -->
        </div>
      </div>
      <div class="pair-user" v-if="getRole">
        <div class="public">
          <span>案件応募情報</span>
          <!-- <span>もっと見る</span> -->
        </div>
        <div class="tab-box">
          <el-table :data="ajApply" :header-cell-style="headClass" border style="width: 100%">
            <el-table-column prop="nickname" label="応募者" align="center">
            </el-table-column>
            <el-table-column prop="projectname" label="漫画名" align="center" ></el-table-column>
            <el-table-column prop="chaptername" label="案件名" align="center" ></el-table-column>
            <el-table-column prop="positionname" label="応募職業" align="center" width="">
            </el-table-column>
            <el-table-column prop="introduce " label="ポートフォリオ" align="center">
              <template scope="scope">
                <p v-for="(item, index) in scope.row.introduce" :key="index">
                  {{ item }}
                </p>
              </template>
            </el-table-column>
            <!-- 主页 -->
            <el-table-column prop="id" label="プロフィール" align="center">
               <template scope="scope">
               <span @click="handleClick_(scope.row)" style="color: #1875d2; cursor: pointer;">プロフィール</span>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="操作" align="center" width="">
              <template scope="scope">
                <template v-if="scope.row.isemploy == 0">
                  <el-button @click="agreeJoin(scope.row)" type="text" icon="el-icon-check" size="small">受入</el-button>
                  <el-button @click="refuseJoin(scope.row)" type="text" icon="el-icon-close" size="small">拒否</el-button>
                </template>
                <template v-else-if="scope.row.isemploy == 1">
                  <span>受入</span>
                </template>
                <template v-else-if="scope.row.isemploy == 2">
                  <span>拒否</span>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="page-box" style="margin-top:10px;text-align:right">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="anjiancurrentPage"
              :page-size="5"
              layout="total,  prev, pager, next, jumper"
              :total="count"
            >
            </el-pagination>
        </div> -->
        </div>
      </div>
      <!-- 查看详情 -->
      <el-dialog title="詳細" :visible.sync="dialogFormVisible" width="45%">
        <div>
          <div class="titleForm">
        <div class="titleImg" v-if="projectInfo.icon">
          <img :src="'/userupload/' + projectInfo.icon" alt="">
        </div>
        <div>
          <el-form label-width = "400">
            <el-form-item label="漫画タイプ:">
              <span style="margin-left: 0;">{{ projectInfo.type == 1 ? "連載" : "読切" }}</span>
            </el-form-item>
            <el-form-item label="タイトル:">
              <span>{{ projectInfo.title }}</span>
            </el-form-item>
            <el-form-item label="ジャンル:">
                <ul style="display: inline-block;" v-for="(item,index) in typeb" :key="index">
                  <li style="margin-left: 15px;">{{item.typeb}}</li>
                </ul>
            </el-form-item>
            <el-form-item label="創作/エッセイ:">
              <span>{{ projectInfo.creativecap == 1? "創作" : "エッセイ" }}</span>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="outline">
        <p>概要</p>
        <div v-html="outline">
          <!-- {{ outline }} -->
        </div>
      </div>
      <div class="titleText">
        <el-form>
            <el-form-item label="企画書:">
              <span style="cursor: pointer; color: #1875d2;" @click="historyDownLoad(projectInfo.proposals)">{{ projectInfo.bookName }}</span>
            </el-form-item>
            <el-form-item label="制作種類:">
              <span>{{ projectInfo.kind == 1? "商業制作":"自主制作"}}</span>
            </el-form-item>
          </el-form>
      </div>
      <div style="line-height:30px">
        <hr v-if="isStatus">
      </div>
      <div>
        <el-form v-if="isStatus">
            <el-form-item label="話のタイトル:">
              <span>{{projectdata.name}}</span>
            </el-form-item>
            <el-form-item label="ヘッダー画像:">
              <img :src="'/userupload/' + projectdata.icon" alt="">
            </el-form-item>
            <el-form-item label="ネーム締め日:">
              <span>{{projectdata.stime}}</span>
            </el-form-item>
            <el-form-item label="校了締切日:">
              <span>{{projectdata.etime}}</span>
            </el-form-item>
          </el-form>
      </div>
    </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogFormVisible = false">確　定</el-button>
        </div>
      </el-dialog>
      <!-- 多个职位招聘弹窗 -->
      <el-dialog title="職業を選択して下さい" :visible.sync="dialogApply" width="30%">
        <div>
          <el-checkbox v-for="(item, index) in recruit_detail.recruitdetail" :key="index" v-model="item.checked">{{
              item.position
          }}</el-checkbox>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogApply = false">取　消</el-button>
          <el-button type="primary" @click="applayConfrim">確　定</el-button>
        </div>
      </el-dialog>
    </div>
    <send-message :sendStatus="sendStatus" @childStatus="childStatus" @sendMessageContent="sendMessageContent"></send-message>
      <el-drawer :visible.sync="drawer" size="50%" direction="rtl">
      <div class="registerInfo">
          <div class="icon" v-if="users.icon">
            <img :src="'/userupload/' + users.icon" alt="">
          </div>
        <el-descriptions :label-style="LS" title="ユーザー情報" :column="1" style="margin-top:20px" border>
          <el-descriptions-item label="アイコン" v-if="users.pic">  <el-avatar :size="80" :src="'/userupload/' + users.pic"></el-avatar> </el-descriptions-item>
        	<el-descriptions-item label="ニックネーム">{{users.nickname}} </el-descriptions-item>
          <el-descriptions-item label="登録属性" >{{ users.loginattr == 1 ? 'クリエイター ' : '編集者'}} </el-descriptions-item>
           <el-descriptions-item v-if="users.loginattr == 1" label="参加したい工程" style="width:100px">
            <span style="padding: 0 5px" v-for="(item,index) in positions">{{item.position}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="媒体">
             <span style="padding: 0 5px" v-for="(item,index) in medias">{{item.media}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="ジャンル">
            <span style="padding: 0 5px" v-for="(item,index) in typebigs">{{item.typeb}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="創作/エッセイ">

            <span v-if="users.creativecap == 1" style="margin-left:15px">創作</span>
            <span v-else-if="users.creativecap == 2" style="margin-left:15px">エッセイ</span>
            <span v-else>創作 エッセイ </span>
          </el-descriptions-item>
          	<el-descriptions-item v-if="users.theme && users.theme != '#'" label="興味のある題材">
              <p v-for="(item,index) in users.theme.split('#')" :key="index">{{item}}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="users.introduction" label="自己PR">
              <p>{{users.introduction}}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="users.cartoon" label="好きな漫画">
				      <p>{{users.cartoon}}</p>
			      </el-descriptions-item>
            <el-descriptions-item v-if="users.cartooner" label="好きな作家">
              <p>{{users.cartooner}}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="users.cute" label="萌え">
              <p>{{users.cute}}</p>
            </el-descriptions-item>
            <template v-if="users.loginattr == '1'">
            <el-descriptions-item label-class-name="my-label" v-if="users.drawmethod" label="作画方法">
              <p>{{users.drawmethod == 1 ? "アナログ" : "デジタル"}}</p>
            </el-descriptions-item>
          </template>
          <template v-else>
            <el-descriptions-item label-class-name="my-label" v-if="users.drawmethod" label="所属編集部">
              <p>{{users.drawmethod == 1 ? "出版社編集部所属" : "フリーランス"}}</p>
            </el-descriptions-item>
          </template>
            <el-descriptions-item v-if="users.reward != '#' && users.loginattr === '1'" label="受賞歴">
              <p>{{ users.reward }}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="users.experience &&  users.loginattr == '1'" label="漫画経験">
              <template v-if="users.experience.length > 1">
                <span style="margin-left:15px">アシスタント</span>
                <span style="margin-left:15px">雑誌掲載</span>
              </template>
            <template  v-else-if="users.experience == 1">
              <span v-if="users.experience[0] == 1">アシスタント</span>
              <span style="margin-left:15px" v-else>雑誌掲載</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item v-if="achievements.length && achievements[0].selfProduVal" label="製作実績">
            <div style="margin-bottom: 10px" class="achive_" v-for="(item,index) in achievements">
              <span v-if="item.selfProduVal" style="padding: 0 10px 0 0">{{item.selfProduVal == '1' ? '商業' : '自主制作'}}</span>
              <span v-if="item.colorVal" style="padding: 0 10px 0 0">{{item.colorVal == '1' ? 'カラー' : '白黒'}} </span>
              <span v-if="item.isSerialVal" style="padding: 0 10px 0 0">{{item.isSerialVal == '1' ? '読切' : '連載'}}</span>
              <span v-if=" item.serialSizeVal" style="padding: 0 10px 0 0">{{ item.serialSizeVal == '1' ? '縦スク' : '見開き' }}</span>
              <p style="padding: 0 10px 0 0" v-if="item.workName">{{ item.workName }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.author">{{ item.author }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.desc">{{ item.desc }}</p>
          </div>
        </el-descriptions-item>
         <el-descriptions-item v-if="users.introduce" label="ポートフォリオ">
            <p>{{users.introduce}}</p>
          </el-descriptions-item>
          <el-descriptions-item v-if="users.adscartoon" label="広告マンガ製作依頼" >{{ users.adscartoon == 1 ? '受ける ' : '受けない'}}</el-descriptions-item>
          <el-descriptions-item v-if="users.drawmethod && users.loginAttr == 2" label="所属編集部" >{{ users.drawmethod == 1 ? '出版社編集部所属 ' : 'フリーランス'}}</el-descriptions-item>
          <el-descriptions-item v-if="users.publish" label="海外への出版" >{{ users.publish == 1 ? '希望する ' : '希望しない'}}</el-descriptions-item>
            </el-descriptions>
      </div>
    </el-drawer>
    <personalDialog ref="addDialog"></personalDialog>
  </div>
</template>

<script>
import service from "@/service";
import sendMessage from "../../../commpoents/sendMessage";
import userInfo from "../../../commpoents/userInfo/index.vue";
// import utils from '@/assets/js/utils'
import personalDialog from './components/personalDialog.vue'
export default {
  components: {
    sendMessage,
    userInfo,
    personalDialog
  },
  data() {
    return {
      LS: {
      'color': '#000',
      'font-weight':'700',
      'text-align': 'right',
      'width': '180px',
      'word-break': 'keep-all'
    },
      drawer:false,
      status: false,
      dialogFormVisible: false,
      dialogApply: false,
      users: {},
      positions:[],
      medias: [],
      typebigs: [],
      userInfo: {},
      loginAttr: 1,
      tableData: [],
      part: [],
      pairUser: [],
      ajRecruit: [],
      qhRecruit: [],
      applyFor: [],
      recruit_detail: [],
      qhApply: [],
      ajApply: [],
      firendList: [],
      messageList: [],
      activeNames: "1",
      resuestList: [],
      caseList: [],
      pushProject: [],
      sendStatus: false,
      pic: "/userupload/",
      fid: "",
      obj: {
        name: 1,
        age: 3,
      },
      role: [],
      themeArr: [],
      typebsArr: [],
      optionArr:[],
      projectContent: '',
      addFirendList: [],
      count: 5,
      ajCount: 0,
      ajzpCount: 0,
      cjajCount:0,
      tjCount: 0,
      currentPage: 1,
      anjiancurrentPage: 1,
      qihuacurrentPage: 1,
      cjxmCount: 0,
      frinedCount: 0,
      qhzpCount: 0,
      projectCount: 0,
      hopeInfo: {
        type: [],
        checked: [],
      },
      media: [],
      classification: [],
      saveLang: [],
      achievements: [],
      achievObj: [
        {
          selfProdu: [
            { value: "1", label: "商業" },
            { value: "2", label: "自主制作" },
          ],
          color: [
            { value: "1", label: "カラー" },
            { value: "2", label: "白黒" },
          ],
          isSerial: [
            { value: "1", label: "読切" },
            { value: "2", label: "連載" },
          ],
          serialSize: [
            { value: "1", label: "縦スク" },
            { value: "2", label: "見開き" },
          ],
          selfProduVal: "",
          colorVal: "",
          isSerialVal: "",
          serialSizeVal: "",
          workName: "",
          author: "",
          desc: "",
        },
      ],
      projectInfo:{},
      typeb:[],
      outline:"",
      projectName:"",
      projectNewMessage:"0",
      projectdata:{},
      isStatus:false,
    };
  },
  mounted() {
    this.getPersonalProjectList({p:1});
    this.getLoginInfo();
    this.getRecruitList();
    this.getApplayList({ operate: 1,p: 1 });
    this.getApplayList({ operate: 2,p: 1 });
    this.getPersonalPushPair(1);
    this.getMessageList();
    this.getPartProList(1);
    this.getQuerestList(1);
    this.pushChapter(1);
    this.getType();
    this.getFriendsList()
    //this.getPushProject() //获取推荐项目列表
  },
  computed: {
    getRole() {
      if (this.role.indexOf('1') > -1) {
        return true
      } else {
        return false
      }
    },
    isShowPartList() {
      if (this.role.indexOf('2') > -1 || this.role.indexOf('4') > -1 || this.role.indexOf('3') > -1 || this.role.indexOf('7') > -1) {
        return true
      } else {
        return false
      }
    },
    isShowCase() {
      if (this.role.indexOf('5') > -1) {
        return true
      } else {
        return false
      }
    },
    isShowFirend() {
      if (this.role.indexOf('2') > -1 || this.role.indexOf('1') > -1) {
        return true
      } else {
        return false
      }
    },
    isAdvanced() {
      if (this.role.indexOf('4') > -1 || this.role.indexOf('3') > -1 || this.role.indexOf('7') > -1) {
        return true
      } else {
        return false
      }
    }

  },
    watch: {
      users: {
        handler(val) {
          let arr = val.typebs.split(",");
          this.typebsArr = [];
          if (this.optionArr.length && arr.length) {
            this.optionArr.forEach((item, index) => {
              for (let i = 0; i < arr.length; i++) {
                if (item.id == arr[i]) {
                  this.typebsArr.push(item);
                }
              }
            });
          }
          this.themeArr = val.theme.split("#");
        },
        deep: true,
      },
  },
  methods: {
    // 下载计划书
    historyDownLoad(data) {
      let fileName = data;
      window.location.href = `/project/downloadrecfile?filename=${fileName}`;
    },
    // 获取注册数据
    filterHopeType(id) {
      let label = "";
      this.hopeInfo.type.forEach((item) => {
        if (id == item.id) {
          label = item.position;
        }
      });
      return label;
    },
    handleCurrentChange(val) {
      this.anjiancurrentPage = val
      this.getApplayList({ operate: 2, p: val})
    },
    qhHandleCurrentChange(val) {
      this.qihuacurrentPage = val
      this.getApplayList({ operate: 1, p: val})
    },
    qhzpHandleCurrentChange(val) {
      service.personal.getRecruitList(2,val).then(res => {
        if (res.status === 200) {
           this.ajzpCount = res.count
           this.ajRecruit =  res.data
           
         }
      })
    },
    cjajHandleCurrentChange(val) {
      this.pushChapter(val);
    },
    tjHandleCurrentChange(val) {
      this.getPersonalPushPair(val);
    },
    cjxmHandleCurrentChange(val) {
      this.getPartProList(val);
    },
    friendHandleCurrentChange(val) {
       this.getQuerestList(val);
    },
    qhZpHandleCurrentChange(val) {
      service.personal.getRecruitList(1,val).then((res) => {
        if (res.status === 200) {
          this.qhRecruit = res.data;
          console.log(res.data,"=-=-======")
          this.qhzpCount = res.count
        }
      });
    },
    projectHandleCurrentChange(val) {
      this.getPersonalProjectList({p:val,keyword:''})
    },
  //获取好友列表
    getFriendsList() {
      service.personal.findGetFriends().then(res => {
        if(res.status === 200) {
          this.addFirendList = res.data
          // this.getPersonalPushPair(1)
          this.getQuerestList(1)
        }
      }).catch(err => {
        console.log(err)
      })
    },
  //获取分类
    getType() {
      service.personal
        .getTypeData()
        .then((res) => {
          if (res.status === 200) {
            this.optionArr = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(e) {
      this.$refs.UpperDrawer.drawer = true;
    },
    sendMessage(data) {
      this.fid = data.recruituserid || data.fromuid;
      this.sendStatus = true;
    },
    childStatus(val) {
      this.sendStatus = val;
    },
    sendMessageContent(text) {
      if (text) {
        let params = {
          fid: this.fid,
          msg: text,
        };
        service.personal
          .sendMessage(params)
          .then((res) => {
            if (res.status === 200) {
              this.$message.success("メッセージ送信しました");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("空送信出来ません！");
      }
    },
    //获取案件列表
    pushChapter(val) {
      service.personal.pushChapter({p:val}).then(res => {
        if (res.status === 200) {
          this.caseList = res.data
          this.cjajCount = res.count
        }
      }).catch(err => {
        console.log(err)
      })
    },
    agree(data, num) {
      const params = {
        fid: data.fromuid,
        isadd: num,
        msgid: data.id,
      };
      service.personal
        .isAddFriend(params)
        .then((res) => {
          if (res.status === 2) {
            this.$message.success("拒否");
          } else if (res.status === 200) {
            this.$message.success("受入");
          } else {
            this.$message.success(res.info);
          }
          this.getFriendsList()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMessageList() {
      service.personal
        .getMessageList()
        .then((res) => {
          if (res.status === 2) {
            let data = res.data;
            this.messageList = data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取好友请求列表
    getQuerestList(page) {
      service.personal
        .getQuerestList({p:page})
        .then((res) => {
          if (res.status === 200) {
            this.resuestList = res.data;
            console.log(res.data)
            this.frinedCount = res.count
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 好友申请
    addDialog(data) {
      this.$refs.addDialog.dialogFormVisible = true
      this.$refs.addDialog.dialogForm(data)
      this.$refs.addDialog.form.name = ''
    },
    getPersonalPushPair(page) {
      service.personal
        .getPersonalPushPair({p:page})
        .then((res) => {
          
          if (res.status === 200) {
            this.pairUser = res.data;
            this.tjCount = res.count
           
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    menuTab(index) {
      this.showTab = index;
    },
    headClass() {
      return "text-align: center;background-color:#eee";
    },
    getLoginInfo() {
      service.personal.getLoginInfo().then((res) => {
        let data = res.data;
        this.userInfo = data.data;
        this.loginAttr = this.userInfo.loginattr;
        this.role = this.userInfo.role.split(",")
      });
    },
    handleClick_(data) {
      this.drawer = true;
      service.personal
        .getUserInfoData({ userid: data.recruituserid || data.userid || data.fromuid })
        .then((res) => {
          if (res.status === 200) {
            this.users = res.data.data;
            this.positions = res.data.positions
            this.medias = res.data.medias
            this.typebigs = res.data.typebigs
            this.achievements = res.data.data.achievements.split("#")
            this.achievements = this.achievements.map(item => {
              let arr = item.split(';')
              return {
                selfProduVal: arr[0],
                colorVal: arr[1],
                isSerialVal: arr[2],
                serialSizeVal: arr[3],
                workName: arr[4],
                author: arr[5],
                desc: arr[6],
              }
            })
            this.media = res.data.medias;
            this.classification = res.data.typebigs
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    personalApply(data) {
      service.personal.personalApply(data).then((res) => {
        if (res.status === 200) {
          this.qhzpHandleCurrentChange(1)
          this.qhZpHandleCurrentChange(1)
          this.$message.success(res.info);
        } else if (res.status === 3) {
          this.$message.warning(res.info);
        } else if (res.status === 1) {
          this.$message.warning(res.info);
        }
        
      });
    },
    // getPersonalProjectList(page) {
    //   service.personal.getPersonalProjectList(page).then((res) => {
    //     if (res.status === 200) {
    //       this.tableData = res.data;
    //       this.projectCount = res.count;
    //     }
    //   });
    // },
    getPersonalProjectList(data) {
      service.personal
        .getProjectList(data)
        .then((res) => {
          if (res.status === 200) {
            this.tableData = res.data;
             this.projectCount = res.count;
          }
        })
        .catch((err) => {});
    },
    //获取推荐项目列表
    getPushProject() {
      service.personal.pushProject().then(res => {
        if (res.status === 200) {
          this.pushProject = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },

    getPartProList(page) {
      service.personal.getPartProList({p:page}).then((res) => {
        if (res.status === 200) {
          this.part = res.data;
          this.cjxmCount = res.count
        }
      });
    },
    goSynopsis(row,status) {
      this.isStatus = status
      this.typeb = [];
      console.log(row);
      this.dialogFormVisible = true;
      this.projectContent = row.content
      this.projectdata = row
      console.log(this.projectdata)
      service.personal.getProjectInfo({ projectid: row.projectid }).then((res) => {
        this.projectInfo = res.data
        let index = this.projectInfo.proposals.indexOf('/')
        let name = this.projectInfo.proposals.substr(index+1)
        this.projectInfo.bookName = name
      let typebigs = JSON.parse(localStorage.getItem("userInfo")).typebigs
        typebigs.forEach(item => {
          let arrtypeb=this.projectInfo.typeb.split(",")
          arrtypeb.forEach((tableId)=>{
            if (tableId == item.id) {
              this.typeb.push(item)
              console.log(this.typeb);
            }
          })
          });
        this.outline = res.data.content;
        this.projectName = res.data.title;
        this.projectNewMessage = res.data.isnew
      });
          console.log(this.typeb);
      // let params = {
      //   projectid: row.projectid,
      //   chapterid: row.chapterid,
      // };
      // service.personal.getChapter(params).then((res) => {
      //   console.log(res);
      // });
    },
    goNewProject() {
      this.$router.push({
        path: "creat-project",
        query: {
          id: 0,
        },
      });
    },
    goMyProject(type) {
      if (type && type == "caseList") {
        this.$router.push({
          path: "/personal-center/personal-project/case-list",
          query: { id: 1, role: this.role.join(',') },
        });
      } else {
        this.$router.push({
          path: "/personal-center/personal-project",
          query: { id: 1, role: this.role.join(',') },
        });
      }
    },
    toDetails(row,beanl) {
      this.$router.push({
        path: "/personal-center/personal-project/project-details",
        query: { pid: row.id, id: 1, showTrue:beanl},
      });
    },
    getRecruitList() {
      service.personal.getRecruitList(1,1).then((res) => {
        if (res.status === 200) {
          this.qhRecruit = res.data;
          this.qhzpCount = res.count
        }
      });
      service.personal.getRecruitList(2,1).then(res => {
         if (res.status === 200) {
           this.ajzpCount = res.count
           this.ajRecruit =  res.data
          //  console.log(this.ajRecruit,"popopopo")
         }
      })
    },
    getApplayList(data) {
      service.personal.getApplayList(data).then((res) => {
        if (data.operate === 2) {
          this.count = res.count
          this.ajApply = res.data;
        } else {
          this.ajCount = res.count,
          this.qhApply = res.data;
        }
      });
    },
    //修改项目信息
    modifyProjectInfo(row) {
     
      this.$router.push({
        path: "creat-project",
        query: {
          id: 0,
          isEdit: true,
          pid:row.id,
          // params: JSON.stringify(row),
        },
      });
    },
    //应聘
    apply(row) {
        let params = {
          projectid: row.projectid,
          recruitid: row.recruitid,
          recruitdetailid: row.id,
          position: row.posid,
          chapterid: row.chapterid,
          createuserid: row.createuserid,
        };
        this.personalApply(params);
    
        // this.dialogApply = true;
        // this.recruit_detail = row;
        // this.recruit_detail.recruitdetail.forEach((item) => {
        //   this.$set(item, "checked", false);
        // });
      
    },
    agreeJoin(row) {
      let params = {
        operate: 1,
        applyid: row.id,
        chapterid: row.chapterid,
        projectid: row.projectid,
        userid: row.recruituserid,
        position: row.position,
        recruitdetailid: row.recruitdetailid,
      };
      this.handleApply(params);
    },
    refuseJoin(row) {
      let params = {
        operate: 2,
        applyid: row.id,
      };
      this.handleApply(params);
    },
    handleApply(data) {
      service.personal.handleApply(data).then((res) => {
        if (res.status === 200) {
          this.getApplayList({ operate: 1,p: this.qihuacurrentPage });
          this.getApplayList({ operate: 2,p: this.anjiancurrentPage});
        } else if (res.status === 201) {
          this.getApplayList({ operate: 2,p: this.anjiancurrentPage });
          this.getApplayList({ operate: 1,p: this.qihuacurrentPage });
        } else if(res.status === 1) {
          this.$message.error(res.info)
        }
      });
    },
    applayConfrim() {
      let arr = [];
      this.recruit_detail.recruitdetail.forEach((item) => {
        if (item.checked) {
          arr.push(item);
        }
      });
      let params = {
        projectid: this.recruit_detail.projectid,
        recruitid: this.recruit_detail.id,
        recruitdetailid: arr[0].id,
        position: arr[0].posid,
        chapterid: this.recruit_detail.chapterid,
        createuserid: this.recruit_detail.createuserid,
      };
      this.personalApply(params);
      this.dialogApply = false;
    },
    //删除消息
    deleteMessage(item) {
      console.log(item)
      service.personal.deleteMessage({msgid:item.id}).then(res => {
        if(res.status === 200) {
          // this.$message.success("メッセージ削除")
           this.getMessageList()
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.main {
  .wrap {
    .message-box {
      border-bottom: 1px solid #eee;
      position: relative;
      >div {
        height: 30px;
        line-height: 30px;
      }
      b {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 20px;
        color: #ccc;
        cursor: pointer;
      }
      p {
        display: flex;
        justify-content: end;
      }
    }
  }

  .style {
    color: #1875d2;
    cursor: pointer;
  }

  // .header {
  // 	width: 100%;
  // 	border-bottom: 1px solid #ddd;
  // 	position:sticky;
  // 	top: 0px;
  // 	background-color: #fff;
  // 	z-index: 888;
  // 	>div {
  // 		ul {
  // 			display: flex;
  // 			padding: 0 40px;
  // 			li {

  // 				height: 67px;
  // 				line-height: 67px;
  // 				cursor: pointer;
  // 				margin-right: 30px;
  // 			}
  // 			li.active {
  // 				border-bottom: 3px solid #333;
  // 			}
  // 		}
  // 	}
  // }
  // .content {
  // 	margin-top: 40px;
  // 	>div {
  // 		padding: 0 40px;
  // 	}
  // }
  .public {
    display: flex;
    margin-bottom: 20px;

    span {
      display: block;
    }

    span:first-child {
      flex: 1;
      color: #333;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    span:last-child {
      color: #666;
      cursor: pointer;
    }
  }

  .my-project {
    margin-top: 10px;

    .style {
      color: #1875d2;
      cursor: pointer;
    }
  }

  .new-project-btn {
    display: flex;
    justify-content: center;
    margin: 40px 0;

    span {
      display: block;
      width: 165px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #f03747;
      color: #f03747;
      font-weight: bold;
      cursor: pointer;
      letter-spacing: 1px;
    }
  }

  .pair-user {
    margin-top: 30px;
  }
  .titleForm {
  margin-top: 20px;
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }

  .titleImg{
    position: relative;
    height: 350px;
    width: 350px;
    margin: 0 auto;
    // height: 100%;
    text-align: center;
    img {
    max-width: 100%;
    max-height: 100%;
    position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    }
  }
}
  .outline {
  p {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0 20px 0;
  }

  div {
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    height: 100px;
    padding: 15px;
  }
}
.titleText {
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
}
  .wrap__ {
       padding: 15px;
    .augi {
      text-align: center;
      margin: 0 auto;
      // display: flex;
      // justify-content: center;
      .img-box {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        display: block;
        margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
      }
    }
    .type {
      margin: 40px 0 0 0;
      .contentBox {
        color: #606266;
        font-size: 14px;
        .content {
          padding: 0 10px;
          min-width: 20px;
          color: #606266;
        }
      }
    }
  }
}
// 注册信息
::v-deep.registerInfo {
  .icon {
    height: 160px;
    text-align: center;
    img {
      max-height: 100%;
    }
  }
  padding: 20px;
  ul {
    width: 100%;
    li {
      span {
        margin-left: 20px;
        font-size: 16px;
      }
      height: 32px;
      h4 {
        font-size: 18px;
      }
      div {
        padding-left: 20px;
      }
    }
  }
}
</style>
